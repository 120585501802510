* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Encode Sans", sans-serif;
  font-style: normal;
}
body .demo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 200px;
  margin: 70px 0 0;
  gap: 20px;
  background-color: #F2FBEF;
}
@media only screen and (max-width: 481px) {
  body .demo {
    padding: 0 0 15px;
    margin: 80px 0 0;
  }
}
body .demo h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}
@media only screen and (max-width: 481px) {
  body .demo h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  body .demo h1 {
    margin: 0;
  }
}
body .demo .formHold {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
@media only screen and (max-width: 481px) {
  body .demo .formHold {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
body .demo .formHold form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}
@media only screen and (max-width: 481px) {
  body .demo .formHold form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
body .demo .formHold form input {
  width: 772px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #CBCBCB;
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
}
body .demo .formHold form input::-moz-placeholder {
  color: #000;
  font-family: Encode Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.9px; /* 155% */
  text-transform: capitalize;
}
body .demo .formHold form input::placeholder {
  color: #000;
  font-family: Encode Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.9px; /* 155% */
  text-transform: capitalize;
}
@media only screen and (max-width: 481px) {
  body .demo .formHold form input {
    width: 327px;
    height: 30px;
    border-radius: 3.399px;
    border: 0.425px solid #CBCBCB;
    background: #FFF;
  }
  body .demo .formHold form input::-moz-placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.854px;
  }
  body .demo .formHold form input::placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.854px;
  }
}
body .demo .formHold form div label {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 772px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #CBCBCB;
  background: #FFF;
  padding: 12px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 27.9px;
}
body .demo .formHold form div label select {
  width: 600px;
  height: 50px;
  border: none;
  border-top: 1px solid #CBCBCB;
  border-bottom: 1px solid #CBCBCB;
  border-radius: 0%;
}
body .demo .formHold form div label select:active {
  border: none;
}
@media only screen and (max-width: 481px) {
  body .demo .formHold form div label {
    width: 327px;
    height: 30px;
    border-radius: 3.399px;
    border: 0.425px solid #CBCBCB;
    background: #FFF;
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.854px;
  }
  body .demo .formHold form div #stuff {
    width: 220px;
    height: 30px;
  }
}
body .demo .formHold form textarea {
  width: 772px;
  height: 200px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #CBCBCB;
  background: #FFF;
}
body .demo .formHold form textarea::-moz-placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 27.9px; /* 155% */
}
body .demo .formHold form textarea::placeholder {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 27.9px; /* 155% */
}
@media only screen and (max-width: 481px) {
  body .demo .formHold form textarea {
    width: 327px;
    height: 150px;
    border-radius: 3.399px;
    border: 0.425px solid #CBCBCB;
    background: #FFF;
  }
  body .demo .formHold form textarea::-moz-placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.854px; /* 98.786% */
  }
  body .demo .formHold form textarea::placeholder {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.854px; /* 98.786% */
  }
}
body .demo .formHold form .cta {
  width: 355px;
  height: 68px;
  background: url(../../images/cta.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; /* 100% */
}
body .demo .formHold form .cta a {
  width: 355px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .demo .formHold form .cta {
    background: url(../../images/ctaMob.svg);
    width: 328px;
    height: 53px;
  }
}/*# sourceMappingURL=demo.css.map */