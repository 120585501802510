@use 'mixins';
@use 'variables';

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    width: 100%;
    height: 100vh;
    font-family: map-get(variables.$fonts , main );
    font-style: normal;

    

    section{
        padding: 50px;
        margin: 15px 0;

        .mob{
            display: none;
        }
    }

    .smallHero{
        @include mixins.flexer;
        justify-content: space-between;
        padding-right: 0;

        .text{
            @include mixins.flexColStart;
            // justify-content: space-between;
            width: 576px;
            // height: 100%;
            // gap: 60px;
            

            .top{
                @include mixins.flexColStart;
                gap: 15px;
                
                h1{
                    @include mixins.head;

                    @include mixins.query(mobile){
                        display: none;
                    }
                }

                p{
                    @include mixins.para;
                }
    
                .ctaHold{
                    @include mixins.cta;
                }
            }

            .trust{
                @include mixins.flexColStart;
                width: 100%;
                gap: 15px;
    
                p{
                    color: #8F8F8F;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 100;
                    line-height: 16px;
                }
    
                ul{
                    @include mixins.flexer;
                    justify-content: space-between;
                    width: 100%;
                    gap: 25px;
                }
            }
        }

        .img{
            position: relative;
            width: 560px;
            // height: 808px;
            background: #F2FBEF;
            border-bottom-left-radius: 24px;

            img{
                position: relative;
                right: 27px;
            }
        }

        @include mixins.query(mobile){
            @include mixins.flexCol;
            padding: 50px;
            gap: 25px;
            margin: 20px 0;

            .text{
                width: 100%;
                @include mixins.flexCol;

                .top{
                    @include mixins.flexCol;

                    .mob{
                        display: block;
                        margin: 0;
                    }
                }

                .trust{
                    display: none;
                }
            }

            .img{
                width: 280px;
                height: 287px;
                // transform: rotate(-90deg);
                flex-shrink: 0;
                border-radius: 12px;
                left: 68px;

                img{
                    width: 215.386px;
                    height: 272px;
                    bottom: 70px;
                }
            }

            .mob{
                display: block;
                margin: 0;
                @include mixins.flexColStart;
                width: 278px;

                p{
                    font-size: 7.446px;
                    // font-style: normal;
                    font-weight: 500;
                    line-height: 7.446px;
                    color: #8F8F8F;
                }

                ul{
                    @include mixins.flexer;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    gap: 20px;

                    li{
                        img{
                            width: 80.166px;
                            height: 30.126px;
                        }
                    }
                }
            }
        }
    }

    .benefit{
        @include mixins.flexCol;
        // padding: 20px;
        gap: 20px;

        .top{
            @include mixins.flexCol;
            padding: 0 200px;

            p{
                color: map-get(variables.$colors , you );
                font-size: 18px;
                font-weight: 500;
                line-height: 50.492px; 
                margin: 0;

                @include mixins.query(mobile){
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26.5px;
                }

                @include mixins.of-type(2){
                    color: #000;
                    text-align: center;
                    @include mixins.para;
                }
            }

            h1{
                @include mixins.head;
                text-align: center;
                margin: 0;
            }

            @include mixins.query(mobile){
                padding: 0;
            }
        }

        .bot{
            @include mixins.flexCol;
            // padding: 20px;

            .holder{
                @include mixins.flexer;
                margin: 20px 0;
                gap: 15px;

                div{
                    @include mixins.flexColStart;
                    width: 608px;
                    height: 458px;
                    border-radius: 24px;
                    background: map-get(variables.$colors , fill);
                    padding: 30px 50px;
                    gap: 20px;

                    .img{
                        width: 96px;
                        height: 96px;
                        border-radius: 50%;
                        background: map-get(variables.$colors , pactSpan ) url(../../images/org.svg) no-repeat center;
                    }

                    .img3{
                        background-image: url(../../images/grow.svg);
                    }
                    
                    @include mixins.of-type(2){
                        .img{
                            background-image: url(../../images/build.svg);
                        }

                        .img2{
                            background-image: url(../../images/trans.svg);
                        }
                    }

                    h4{
                        color: #000;
                        font-family: Encode Sans;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 40px; 
                        margin: 0;
                        // width: 255px;
                        // height: 88px;
                    }

                    p{
                        @include mixins.para;
                    }


                }

                @include mixins.query(mobile){
                    @include mixins.flexCol;
                    margin: 8px 0;
                    gap: 10px;

                    div{
                        @include mixins.flexColStart;
                        width: 260px;
                        height: 328px;
                        border-radius: 10.226px;
                        padding: 10px;

                        .img{
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            background: map-get(variables.$colors , pactSpan ) url(../../images/orgMob.svg) no-repeat center;
                        }

                        .img3{
                            background-image: url(../../images/growMob.svg);
                        }
                        
                        @include mixins.of-type(2){
                            .img{
                                background-image: url(../../images/buildMob.svg);
                            }
    
                            .img2{
                                background-image: url(../../images/transMob.svg);
                            }
                        }

                        h4{
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 25.1px; 
                            // width: 274px;
                            // height: 59px;
                        }

                        p{
                            text-align: left;
                        }
                    }
                }
            }
        }
    }

    .features{
        @include mixins.flexCol;
        margin-bottom: 40px;
        .top{
            @include mixins.flexCol;
            padding: 0 200px;

            @include mixins.query(mobile){
                padding: 0;
            }

            p{
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 50.492px; /* 280.51% */
                text-transform: uppercase;
                color: var(--colours-primary-blue-100, #115BC7);                
            }

            h1{
                @include mixins.head;
                text-align: center;
            }
        }

        .bot{
            @include mixins.flexCol;
            margin: 30px 0;
            gap: 15px;

            .holder{
                @include mixins.flexer;
                // justify-content: space-between;
                gap: 15px;

                @include mixins.query(mobile){
                    @include mixins.flexCol;
                }
                

                .hold{
                    @include mixins.flexColStart;
                    padding: 15px 10px;
                    width: 328px;
                    // width: 352px;
                    // height: 392px;
                    // gap: 15px;

                    @include mixins.query(mobile){
                        @include mixins.flexCol;
                        padding: 0;
                    }

                    .img{
                        width: 112px;
                        height: 112px;
                        border-radius: 50%;
                        background: map-get(variables.$colors , backer2 ) url(../../images/vir.svg) no-repeat center;

                        @include mixins.query(mobile){
                            width: 64px;
                            height: 64px;
                            background: map-get(variables.$colors , backer2 ) url(../../images/virMob.svg) no-repeat center;
                        }
                    }

                    h4{
                        color: #000;
                        font-family: Encode Sans;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 73.4px;
                        
                        @include mixins.query(mobile){
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 73.4px;
                        }
                    }

                    p{
                        color: #000;
                        font-family: Encode Sans;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 33.9px;
                        height: 209px; 
                        margin: 0;
                        
                        @include mixins.query(mobile){
                            text-align: center;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 33.9px;
                            height: auto;
                        }
                    }
                }

                .hold2{
                    .img{
                        background: map-get(variables.$colors , backer2 ) url(../../images/pop.svg) no-repeat center;

                        @include mixins.query(mobile){
                            background: map-get(variables.$colors , backer2 ) url(../../images/popMob.svg) no-repeat center;
                        }
                    }
                }

                .hold3{
                    .img{
                        background: map-get(variables.$colors , backer2 ) url(../../images/ana.svg) no-repeat center;

                        @include mixins.query(mobile){
                            background: map-get(variables.$colors , backer2 ) url(../../images/anaMob.svg) no-repeat center;
                        }
                    }

                    p{
                        width: 352px;
                        height: 183px;

                        @include mixins.query(mobile){
                            width: auto;
                            height: auto;
                        }
                    }
                }

                .hold4{
                    .img{
                        background: map-get(variables.$colors , backer2 ) url(../../images/infi.svg) no-repeat center;

                        @include mixins.query(mobile){
                            background: map-get(variables.$colors , backer2 ) url(../../images/unlim.svg) no-repeat center;
                        }
                    }
                }

                .hold5{
                    .img{
                        background: map-get(variables.$colors , backer2 ) url(../../images/inter.svg) no-repeat center;

                        @include mixins.query(mobile){
                            background: map-get(variables.$colors , backer2 ) url(../../images/intMob.svg) no-repeat center;
                        }
                    }
                }

                .hold6{
                    .img{
                        background: map-get(variables.$colors , backer2 ) url(../../images/omni.svg) no-repeat center;

                        @include mixins.query(mobile){
                            background: map-get(variables.$colors , backer2 ) url(../../images/omniMob.svg) no-repeat center;
                        }
                    }

                    p{
                        width: 352px;
                        height: 209px;

                        @include mixins.query(mobile){
                            width: auto;
                            height: auto;
                        }
                    }
                }

                .hold7{
                    .img{
                        width: 64px;
                        height: 64px;
                        background: map-get(variables.$colors , backer2 ) url(../../images/forw.svg) no-repeat center;
                    }
                }

                .hold8{
                    .img{
                        width: 64px;
                        height: 64px;
                        background: map-get(variables.$colors , backer2 ) url(../../images/hunt.svg) no-repeat center;
                    }
                }

                .hold9{
                    .img{
                        width: 64px;
                        height: 64px;
                        background: map-get(variables.$colors , backer2 ) url(../../images/queM.svg) no-repeat center;
                    }
                }
            }

            .mob{
                display: none;

                @include mixins.query(mobile){
                    display: block;
                }
            }
        }

        .ctaHold{
            @include mixins.cta;
            text-transform: capitalize;
        }
    }
}