*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Encode Sans', sans-serif;;
}

.wrapper {
    max-width: 500px;
    width: 100%;
    margin: 20px auto;
    padding: 30px;
}

.wrapper .title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 25px;
    color: black;
    text-transform: inherit;
    text-align: center;
}

.wrapper .form{
    width: 100%;
}

.wrapper .form .input_field {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}

.wrapper .form .input_field input,
.wrapper .form .input_field textarea {
    width: 100%;
    outline: none;
    border: 1px solid #d5dbd9;
    font-size: 15px;
    padding: 8px 10px;
    border-radius: 3px;
    transition: all 0.3s ease;
   /* display: block;
    font-weight: 500;
    margin-bottom: 5px;
    padding-right: 245px;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-radius: 5px;
    border: 1px solid #d5dbd9;*/
}

.wrapper .form .input_field textarea {
    resize: none;
    height: 150px;
  /*  display: inline-flexbox;
    font-weight: 500;
    padding-right: 245px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid #d5dbd9;*/
}

.wrapper .form .input_field .custom_select {
    position: relative;
    width: 100%;
    height: 37px;
}

.wrapper .form .input_field .custom_select select{
    border: 1px solid #d5dbd9;
    width: 100%;
    height: 100%;
    padding: 8px 10px;
    border-radius: 3px;
    color: grey;
    font-size: 15px;
    outline: none;
}


.wrapper .form .btn {
    background: #43ca11;
    font-weight: 900;
    border-radius: 4px;
    padding: 14px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    margin-top: 25px;
    cursor: pointer;
    outline: none;
}

.wrapper .form .btn .button {
    color: white;
}

.wrapper .form .input_field .text:last-child {
    margin-bottom: 0;
}



@media screen and (max-width: 420px) 
{
    .wrapper .form .input_field{
        flex-direction: column;
        align-items: flex-start;
    }

    .wrapper .form .input_field input {
        margin-bottom: 5px;
    }
    .wrapper .form .btn {
        width: 100%;
    }
    /*.wrapper .form .input_field{
        flex-direction: column;
        align-items: flex-start;
    }
    
    .wrapper .title {
        font-size: 15px;
        font-weight: 700;
        line-height: 35px;
        margin-right: 25px;
        
    }*/
}