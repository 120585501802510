* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Encode Sans", sans-serif;
  font-style: normal;
}
body section {
  padding: 50px;
  margin: 15px 0;
}
body section .mob {
  display: none;
}
body .smallHero {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  padding-right: 0;
}
body .smallHero .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 576px;
}
body .smallHero .text .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
body .smallHero .text .top h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  body .smallHero .text .top h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  body .smallHero .text .top h1 {
    display: none;
  }
}
body .smallHero .text .top p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  body .smallHero .text .top p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .smallHero .text .top .ctaHold {
  width: 355px;
  height: 68px;
  background: url(../../images/cta.svg);
  display: flex;
  align-items: center;
  justify-content: center;
}
body .smallHero .text .top .ctaHold a {
  width: 355px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .smallHero .text .top .ctaHold {
    background: url(../../images/ctaMob.svg);
    width: 328px;
    height: 53px;
  }
}
body .smallHero .text .trust {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 15px;
}
body .smallHero .text .trust p {
  color: #8F8F8F;
  font-size: 16px;
  font-style: normal;
  font-weight: 100;
  line-height: 16px;
}
body .smallHero .text .trust ul {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  gap: 25px;
}
body .smallHero .img {
  position: relative;
  width: 560px;
  background: #F2FBEF;
  border-bottom-left-radius: 24px;
}
body .smallHero .img img {
  position: relative;
  right: 27px;
}
@media only screen and (max-width: 481px) {
  body .smallHero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px;
    gap: 25px;
    margin: 20px 0;
  }
  body .smallHero .text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  body .smallHero .text .top {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  body .smallHero .text .top .mob {
    display: block;
    margin: 0;
  }
  body .smallHero .text .trust {
    display: none;
  }
  body .smallHero .img {
    width: 280px;
    height: 287px;
    flex-shrink: 0;
    border-radius: 12px;
    left: 68px;
  }
  body .smallHero .img img {
    width: 215.386px;
    height: 272px;
    bottom: 70px;
  }
  body .smallHero .mob {
    display: block;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 278px;
  }
  body .smallHero .mob p {
    font-size: 7.446px;
    font-weight: 500;
    line-height: 7.446px;
    color: #8F8F8F;
  }
  body .smallHero .mob ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;
    gap: 20px;
  }
  body .smallHero .mob ul li img {
    width: 80.166px;
    height: 30.126px;
  }
}
body .benefit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
body .benefit .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 200px;
}
body .benefit .top p {
  color: #115BC7;
  font-size: 18px;
  font-weight: 500;
  line-height: 50.492px;
  margin: 0;
}
@media only screen and (max-width: 481px) {
  body .benefit .top p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.5px;
  }
}
body .benefit .top p:nth-of-type(2) {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  body .benefit .top p:nth-of-type(2) {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
body .benefit .top h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  margin: 0;
}
@media only screen and (max-width: 481px) {
  body .benefit .top h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  body .benefit .top {
    padding: 0;
  }
}
body .benefit .bot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
body .benefit .bot .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 15px;
}
body .benefit .bot .holder div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 608px;
  height: 458px;
  border-radius: 24px;
  background: #EFF5FB;
  padding: 30px 50px;
  gap: 20px;
}
body .benefit .bot .holder div .img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: #E4EFFF url(../../images/org.svg) no-repeat center;
}
body .benefit .bot .holder div .img3 {
  background-image: url(../../images/grow.svg);
}
body .benefit .bot .holder div:nth-of-type(2) .img {
  background-image: url(../../images/build.svg);
}
body .benefit .bot .holder div:nth-of-type(2) .img2 {
  background-image: url(../../images/trans.svg);
}
body .benefit .bot .holder div h4 {
  color: #000;
  font-family: Encode Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
}
body .benefit .bot .holder div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  body .benefit .bot .holder div p {
    text-align: center;
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  body .benefit .bot .holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 8px 0;
    gap: 10px;
  }
  body .benefit .bot .holder div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 260px;
    height: 328px;
    border-radius: 10.226px;
    padding: 10px;
  }
  body .benefit .bot .holder div .img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E4EFFF url(../../images/orgMob.svg) no-repeat center;
  }
  body .benefit .bot .holder div .img3 {
    background-image: url(../../images/growMob.svg);
  }
  body .benefit .bot .holder div:nth-of-type(2) .img {
    background-image: url(../../images/buildMob.svg);
  }
  body .benefit .bot .holder div:nth-of-type(2) .img2 {
    background-image: url(../../images/transMob.svg);
  }
  body .benefit .bot .holder div h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25.1px;
  }
  body .benefit .bot .holder div p {
    text-align: left;
  }
}
body .features {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
}
body .features .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 200px;
}
@media only screen and (max-width: 481px) {
  body .features .top {
    padding: 0;
  }
}
body .features .top p {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.492px; /* 280.51% */
  text-transform: uppercase;
  color: var(--colours-primary-blue-100, #115BC7);
}
body .features .top h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}
@media only screen and (max-width: 481px) {
  body .features .top h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
body .features .bot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;
  gap: 15px;
}
body .features .bot .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
body .features .bot .holder .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 10px;
  width: 328px;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
  }
}
body .features .bot .holder .hold .img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: #F2FBEF url(../../images/vir.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold .img {
    width: 64px;
    height: 64px;
    background: #F2FBEF url(../../images/virMob.svg) no-repeat center;
  }
}
body .features .bot .holder .hold h4 {
  color: #000;
  font-family: Encode Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 73.4px;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.4px;
  }
}
body .features .bot .holder .hold p {
  color: #000;
  font-family: Encode Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.9px;
  height: 209px;
  margin: 0;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold p {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.9px;
    height: auto;
  }
}
body .features .bot .holder .hold2 .img {
  background: #F2FBEF url(../../images/pop.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold2 .img {
    background: #F2FBEF url(../../images/popMob.svg) no-repeat center;
  }
}
body .features .bot .holder .hold3 .img {
  background: #F2FBEF url(../../images/ana.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold3 .img {
    background: #F2FBEF url(../../images/anaMob.svg) no-repeat center;
  }
}
body .features .bot .holder .hold3 p {
  width: 352px;
  height: 183px;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold3 p {
    width: auto;
    height: auto;
  }
}
body .features .bot .holder .hold4 .img {
  background: #F2FBEF url(../../images/infi.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold4 .img {
    background: #F2FBEF url(../../images/unlim.svg) no-repeat center;
  }
}
body .features .bot .holder .hold5 .img {
  background: #F2FBEF url(../../images/inter.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold5 .img {
    background: #F2FBEF url(../../images/intMob.svg) no-repeat center;
  }
}
body .features .bot .holder .hold6 .img {
  background: #F2FBEF url(../../images/omni.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold6 .img {
    background: #F2FBEF url(../../images/omniMob.svg) no-repeat center;
  }
}
body .features .bot .holder .hold6 p {
  width: 352px;
  height: 209px;
}
@media only screen and (max-width: 481px) {
  body .features .bot .holder .hold6 p {
    width: auto;
    height: auto;
  }
}
body .features .bot .holder .hold7 .img {
  width: 64px;
  height: 64px;
  background: #F2FBEF url(../../images/forw.svg) no-repeat center;
}
body .features .bot .holder .hold8 .img {
  width: 64px;
  height: 64px;
  background: #F2FBEF url(../../images/hunt.svg) no-repeat center;
}
body .features .bot .holder .hold9 .img {
  width: 64px;
  height: 64px;
  background: #F2FBEF url(../../images/queM.svg) no-repeat center;
}
body .features .bot .mob {
  display: none;
}
@media only screen and (max-width: 481px) {
  body .features .bot .mob {
    display: block;
  }
}
body .features .ctaHold {
  width: 355px;
  height: 68px;
  background: url(../../images/cta.svg);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
body .features .ctaHold a {
  width: 355px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  body .features .ctaHold {
    background: url(../../images/ctaMob.svg);
    width: 328px;
    height: 53px;
  }
}/*# sourceMappingURL=busi.css.map */