@use 'mixins';
@use 'variables';

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body{
    width: 100%;
    height: 100vh;
    font-family: map-get(variables.$fonts , main );
    font-style: normal;
    // padding: 70px;
    // margin: 50%;

    .demo{
        @include mixins.flexCol;
        padding: 20px 200px;
        margin: 70px 0 0;
        gap: 20px;
        background-color: map-get(variables.$colors , backer2 );

        @include mixins.query(mobile){
            padding: 0 0 15px;
            margin: 80px 0 0;
        }

        h1{
            @include mixins.head;
            text-align: center;
    
            @include mixins.query(mobile){
                margin: 0;
            }
        }

        .formHold{
            @include mixins.flexColStart;
            gap: 20px;

            @include mixins.query(mobile){
                @include mixins.flexCol;
            }

            form{
                @include mixins.flexColStart;
                gap: 18px;

                @include mixins.query(mobile){
                    @include mixins.flexCol;
                }

                input{
                    width: 772px;
                    height: 50px;
                    border-radius: 8px;
                    border: 1px solid #CBCBCB;
                    background: #FFF;
                    @include mixins.flexer;
                    padding-left: 12px;

                    &::placeholder{
                        color: #000;
                        font-family: Encode Sans;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 27.9px; /* 155% */
                        text-transform: capitalize;
                    }

                    @include mixins.query(mobile){
                        width: 327px;
                        height: 30px;
                        border-radius: 3.399px;
                        border: 0.425px solid #CBCBCB;
                        background: #FFF;
    
                        &::placeholder{
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 11.854px;
                        }
                    }
                }

                div{
                    // @include mixins.flexer;
                    // justify-content: space-between;
                    // width: 772px;
                    // height: 64px;
                    // border-radius: 8px;
                    // border: 1px solid #CBCBCB;
                    // background: #FFF;
                    // padding: 12px;
    
                    label{
                        @include mixins.flexer;
                        justify-content: space-between;
                        width: 772px;
                        height: 50px;
                        border-radius: 8px;
                        border: 1px solid #CBCBCB;
                        background: #FFF;
                        padding: 12px;
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 27.9px;

                        select{
                            width: 600px;
                            height: 50px;
                            border: none;
                            border-top: 1px solid #CBCBCB;
                            border-bottom: 1px solid #CBCBCB;
                            border-radius: 0%;
        
                            &:active{border: none;}
                        }
                    }
    
                    
    
                    @include mixins.query(mobile){
                       
    
                        label{
                            width: 327px;
                            height: 30px;
                            border-radius: 3.399px;
                            border: 0.425px solid #CBCBCB;
                            background: #FFF;
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 11.854px;
                            // border: 1px solid red;
                        }
    
                        #stuff{
                            width: 220px;
                            height: 30px;
                            // border: 1px solid red;

    
                            // option{
                            //     width: 220px;
                            //     height: 48px;
                            //     // border-radius: none;
                            // }
                        }
                    }
                }

                textarea{
                    width: 772px;
                    height: 200px;
                    padding: 12px;
                    border-radius: 8px;
                    border: 1px solid #CBCBCB;
                    background: #FFF;
    
                    &::placeholder{
                        color: #000;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 27.9px; /* 155% */
                    }       
                    
                    @include mixins.query(mobile){
                        width: 327px;
                        height: 150px;
                        border-radius: 3.399px;
                        border: 0.425px solid #CBCBCB;
                        background: #FFF;
    
                        &::placeholder{
                            color: #000;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 11.854px; /* 98.786% */
                        }
                    }
                }

                .cta{
                    @include mixins.cta;
                    border: none;
                    color: #FFF;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 16px; /* 100% */
                }
            }
        }
    }
}

// .demo{
//     @include mixins.flexCol;
//     padding: 20px 200px;

//     @include mixins.query(mobile){
//         padding: 0;
//     }

//     h1{
//         @include mixins.head;
//         text-align: center;

//         @include mixins.query(mobile){
//             margin: 0;
//         }
//     }

//     .formHold{
//         @include mixins.flexColStart;
//         gap: 20px;

//         @include mixins.query(mobile){
//             @include mixins.flexCol;
//         }

//         form{
//             @include mixins.flexColStart;
//             gap: 18px;

//             @include mixins.query(mobile){
//                 @include mixins.flexCol;
//             }

//             input{
//                 width: 772px;
//                 height: 64px;
//                 border-radius: 8px;
//                 border: 1px solid #CBCBCB;
//                 background: #FFF;
//                 @include mixins.flexer;
//                 padding-left: 12px;

//                 &::placeholder{
//                     color: #000;
//                     font-family: Encode Sans;
//                     font-size: 18px;
//                     font-style: normal;
//                     font-weight: 400;
//                     line-height: 27.9px; /* 155% */
//                     text-transform: capitalize;
//                 }

//                 @include mixins.query(mobile){
//                     width: 327px;
//                     height: 48px;
//                     border-radius: 3.399px;
//                     border: 0.425px solid #CBCBCB;
//                     background: #FFF;

//                     &::placeholder{
//                         color: #000;
//                         font-size: 12px;
//                         font-weight: 400;
//                         line-height: 11.854px;
//                     }
                    
//                 }
//             }

//             div{
//                 @include mixins.flexer;
//                 justify-content: space-between;
//                 width: 772px;
//                 height: 64px;
//                 border-radius: 8px;
//                 border: 1px solid #CBCBCB;
//                 background: #FFF;
//                 padding: 12px;

//                 label{
//                     color: #000;
//                     font-size: 18px;
//                     font-weight: 400;
//                     line-height: 27.9px;
//                 }

//                 select{
//                     width: 600px;
//                     height: 64px;
//                     border: none;
//                     border-top: 1px solid #CBCBCB;
//                     border-bottom: 1px solid #CBCBCB;
//                     border-radius: 0%;

//                     &:active{border: none;}
//                 }

//                 @include mixins.query(mobile){
//                     width: 327px;
//                     height: 48px;
//                     border-radius: 3.399px;
//                     border: 0.425px solid #CBCBCB;
//                     background: #FFF;

//                     label{
//                         color: #000;
//                         font-size: 12px;
//                         font-weight: 400;
//                         line-height: 11.854px;
//                     }

//                     select{
//                         width: 220px;
//                         height: 48px;

//                         // option{
//                         //     width: 220px;
//                         //     height: 48px;
//                         //     // border-radius: none;
//                         // }
//                     }
//                 }
//             }

//             textarea{
//                 width: 772px;
//                 height: 390px;
//                 padding: 12px;
//                 border-radius: 8px;
//                 border: 1px solid #CBCBCB;
//                 background: #FFF;

//                 &::placeholder{
//                     color: #000;
//                     font-size: 18px;
//                     font-weight: 400;
//                     line-height: 27.9px; /* 155% */
//                 }

//                 @include mixins.query(mobile){
//                     width: 327px;
//                     height: 250px;
//                     border-radius: 3.399px;
//                     border: 0.425px solid #CBCBCB;
//                     background: #FFF;

//                     &::placeholder{
//                         color: #000;
//                         font-size: 12px;
//                         font-weight: 400;
//                         line-height: 11.854px; /* 98.786% */
//                     }
//                 }
//             }
//         }

//         .cta{
//             @include mixins.cta;
//             border: none;
//             color: #FFF;
//             font-size: 16px;
//             font-weight: 500;
//             line-height: 16px; /* 100% */
//         }
//     }

    
// }