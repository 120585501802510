.b-0 {
    border: 0px !important;
}
.bb-0 {
    border-bottom: 0px !important ;
}
.bt-0 {
    border-top: 0px !important ;
}

.br-10px {
    border-radius: 10px !important;
}
.br-15px {
    border-radius: 15px !important;
}
.br-20px {
    border-radius: 20px !important;
}
.br-30px {
    border-radius: 30px !important;
}


.l--10px {
    left: -10px;
}
.l--10px {
    left: -20px;
}
.l--10px {
    left: -30px;
}
.l--10px {
    left: -40px;
}
.pointer-cursor {
    cursor: pointer !important;
}
.h-fit {
    height: fit-content !important;
}
.mh-100vh {
    min-height: 100vh !important;
}
.f14 {
    font-size: 14px;
}
.ml-10 {
    margin-left: -10px;    
}
.ml-6vw {
    margin-left: 6vw;    
}
.ml-7vw {
    margin-left: 7vw;    
}
.ml--10px {
    margin-left: -10px !important;
}
.ml--20px {
    margin-left: -20px !important;
}
.ml--30px {
    margin-left: -30px !important;
}
.ml--40px {
    margin-left: -400px !important;
}
.mr-40px {
    margin-right: 40px !important;    
}
.mr-50px {
    margin-right: 50px !important;    
}
.mr-60px {
    margin-right: 60px !important;    
}
.mr-70px {
    margin-right: 70px !important;    
}
.mr-100px {
    margin-right: 100px !important;    
}
.mr-6vw {
    margin-right: 6vw !important;
}
.mr-7vw {
    margin-right: 7vw !important;
}
.mr-8vw {
    margin-right: 8vw !important;    
}
.mr-8vw {
    margin-right: 9vw !important;    
}
.mt--1px {
    margin-top: -1px !important;
}
.mt--2px {
    margin-top: -2px !important;
}
.mt--3px {
    margin-top: -3px !important;
}
.mt--10px {
    margin-top: -10px !important;
}
.mt--20px {
    margin-top: -20px !important;
}
.mt--30px {
    margin-top: -30px !important;
}
.mt--40px {
    margin-top: -40px !important;
}
.mt--100px {
    margin-top: -100px !important;
}
.mt--200px {
    margin-top: -200px !important;
}
.mt--230px {
    margin-top: -230px !important;
}
.mt--240px {
    margin-top: -240px !important;
}
.mt--250px {
    margin-top: -250px !important;
}
.mt--260px {
    margin-top: -260px !important;
}
.mt--270px {
    margin-top: -270px !important;
}
.mt--280px {
    margin-top: -280px !important;
}
.mt--300px {
    margin-top: -300px !important;
}
.mt-10px {
    margin-top: 10px !important;
}
.mt-20px {
    margin-top: 20px !important;
}
.mt-30px {
    margin-top: 30px;
}
.mt-40px {
    margin-top: 40px;
}
.mt-50px {
    margin-top: 50px !important;
}
.mt-60px {
    margin-top: 60px !important;
}
.mt-70px {
    margin-top: 70px !important;
}
.mt-75px {
    margin-top: 75px;
}
.mt-80px {
    margin-top: 80px;
}
.mt-90px {
    margin-top: 90px;
}
.mt-100px {
    margin-top: 100px;
}
.mt-150px {
    margin-top: 150px;
}
.mt-160px {
    margin-top: 160px;
}
.mt-170px {
    margin-top: 170px;
}
.mt-180px {
    margin-top: 180px;
}
.mt-200px {
    margin-top: 200px;
}
.mt-300px {
    margin-top: 300px;
}
.mt10 {
    margin-top: 10px;
}
.mt12 {
    margin-top: 12px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.mt25 {
    margin-top: 25px;
}
.mb0 {
    margin-bottom: 0px;
}
.mb-5px {
    margin-bottom: 5px;
}
.mb-8px {
    margin-bottom: 8px;
}
.mb-10px {
    margin-bottom: 10px;
}
.mb-20px {
    margin-bottom: 20px;
}
.mb-30px {
    margin-bottom: 30px;
}
.mb-100px {
    margin-bottom: 100px;
}
.mb-200px {
    margin-bottom: 100px;
}
.mb--2px {
    margin-bottom: -2px;
}
.mx-2vw {
    margin-left: 2vw !important;
    margin-right: 2vw !important;
}


.pt-0 {
    padding-top: 0px !important;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.pb-10px {
    padding-bottom: 10px !important;
}
.pb-20px {
    padding-bottom: 20px !important;
}
.pb-30px {
    padding-bottom: 30px !important;
}
.pb-40px {
    padding-bottom: 40px !important;
}
.pb-50px {
    padding-bottom: 50px !important;
}
.pb-100px {
    padding-bottom: 100px !important;
}
.pb-200px {
    padding-bottom: 200px !important;
}
.pb-280px {
    padding-bottom: 280px !important;
}
.pb-290px {
    padding-bottom: 290px !important;
}
p.pb-300px {
    padding-bottom: 300px !important;
}
.pb-310px {
    padding-bottom: 310px !important;
}
.pb-320px {
    padding-bottom: 320px !important;
}
.pb-330px {
    padding-bottom: 330px !important;
}
.pb-350px {
    padding-bottom: 350px !important;
}
.pl-0px {
    padding-left: 0px !important;    
}    
.pl-40px {
    padding-left: 40px !important;    
}    
.pl-50px {
    padding-left: 50px !important;
}
.pl-70px {
    padding-left: 70px !important;
}
.pl-4vw {
    padding-left: 4vw !important;
}
.pl-5vw {
    padding-left: 5vw !important;
}
.pl-6vw {
    padding-left: 6vw !important;
}
.pl-7vw {
    padding-left: 7vw !important;
}
.pl-9vw {
    padding-left: 9vw !important;
}
.pl-30vw {
    padding-left: 30vw !important;
}
.pl-50per {
    padding-left: 50% !important;
}
.pr-0 {
    padding-right: 0px !important;    
}    
.pr-50px {
    padding-right: 50px !important;
}
.pr-5vw {
    padding-right: 5vw !important;
}
.pr-6vw {
    padding-right: 6vw !important;
}
.pr-7vw {
    padding-right: 7vw !important;
}

.pr-9vw {
    padding-right: 9vw !important;
}
.pr-30vw {
    padding-right: 30vw !important;
}
.pt-0px {
    padding-top: 0px !important;
}
.pt-40px {
    padding-top: 40px !important;
}
.pt-50px {
    padding-top: 50px !important;
}
.pt-70px {
    padding-top: 70px !important;
}
.pt-100px {
    padding-top: 100px !important;
}
.w-fit {
    width: 300px !important;
}
.w-26vw {
    width: 26vw;
}
.w-28vw {
    width: 28vw !important;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.g-0px {
    gap: 0px !important;
}
.g-10px {
    gap: 10px !important;
}
.g-30px {
    gap: 30px;
}
.g-50px {
    gap: 50px;
}
.g-100px {
    gap: 100px;
}
.g-200px {
    gap: 200px;
}
.fs-xxs {
    font-size: xx-small !important;
}
.fs-sm {
    font-size: small !important;
}
.fs-1rem {
    font-size: 1rem;
}
.fs-1p2rem {
    font-size: 1.2rem !important;
}
.fs-1p5rem {
    font-size: 1.5rem;
}
.fs-1em {
    font-size: 1em !important;
}
.fs-1p2em {
    font-size: 1.2em !important;
}
.fs-1p3em {
    font-size: 1.3em !important;
}
.fs-1p5em {
    font-size: 1.5em !important;
}
.fs-2em {
    font-size: 2em !important;
}
.fs-3em {
    font-size: 3em !important;
}
.fs-4em {
    font-size: 4em !important;
}
.fw-400 {
    font-weight: 400;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700 !important;
}
.fw-800 {
    font-weight: 800 !important;
}
.fw-900 {
    font-weight: 900 !important;
}
.fw-1000 {
    font-weight: 1000 !important;
}
.lh-normal {
    line-height: normal;
}
.lh-30px {
    line-height: 30px;
}
.lh-50px {
    line-height: 50px;
}
.lh-60px {
    line-height: 60px;
}
.ls-5px {    
    letter-spacing: 5px;
}

// sizing 
.small-caps {
    font-variant: small-caps;    
}
// relative positionings
.l-30vw {
    left: 30vw;
}
.l-35vw {
    left: 35vw;
}
.t-0px {
    top: 0px !important;
}
.t-10px {
    top: 10px;
}
.t-10px {
    top: 10px;
}
.t-20px {
    top: 20px;
}
.t-30px {
    top: 30px;
}
.t-40px {
    top: 40px;
}
.t-50px {
    top: 50px;
}
.t-7vh {
    top: 7vh !important;
}
.t-10vh {
    top: 10vh !important;
}
.t-10vw {
    top: 10vw !important;
}
.t-15vw {
    top: 15vw !important;
}
.t-20vw {
    top: 20vw !important;
}
.t-12px {
    top: 12px;
}
.t--5px {
    top: -5px;
}
.t--4px {
    top: -4px;
}
.w50per-50px {
    width: calc(50% - 100px);
}

// z-indexes
.z-5 {
    z-index: 5;
}
.z-6 {
    z-index: 6;
}
.z-10 {
    z-index: 10;
}

