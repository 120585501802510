$gen-color:  #3EBF0F;
$gen-bg-sec-color:  #FAFAFA;
$faded-green: #E6F7E0;
$faded-blue: #EFF5FB;
$faded-text: #C5C5C5;
$gen-border-line: 0.5px solid #3EBF0F;
$gen-blue-bg: #115BC7;


@use 'spacings';
@use 'standard';


body {
    font-family: 'encode sans' !important;
    width: 100%;
    height: 100%;
    color: rgba(225, 225, 225, .9);
    background-color: black;
}
a {
    text-decoration: none !important;
}
.no-scroll {
    overflow: hidden;
}
#spinner {
    position: fixed;
    width: 20px;
    height: 20px;
    background-color: $gen-color;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); // Try with standard modal
    z-index: 10;
    animation-name: spin;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}
.hidden {
    display: none !important;
}
.carousel {
    transition: all .3s;
}
.carousel:hover {
    transform: scale(1.01);
    transition: all .3s;
}
.green-text {
    color: $gen-color;
}
.gen-bg-color {
    background-color: $faded-green !important; //#F2FBEF
}
.gen-bg-sec-color {
    background-color: $gen-bg-sec-color !important;
}
.green-bg-color {
    background-color: $gen-color !important;
}
.blue-bg-color {
    background-color: $gen-blue-bg !important;
}
.green-btn {
    background-color: $gen-color !important;
    border: 0px;
    transition: all .2s !important;
}
.green-btn:hover {
    transform: scale(.99);
    transition: all .1s ;
}
.green-btn:active {
    transform: scale(1);
}
.gen-border-bottom {
    border-bottom: 0.5px solid rgb(199, 237, 185);
}
.faded-text {
    color: #C5C5C5 !important;
}
.faded-green-bg {
    background-color: $faded-green !important;
}
.faded-blue-bg {
    background-color: $faded-blue !important;
}
.accordion-button {
    font-weight: 1000;
    color: black !important;
  }
  .enterprise .accordion-button {
    background-color: transparent !important;
 }
 .personal .accordion-button {
    background-color: $faded-blue !important;
 }
//   .accordion-body {
//     background-color: inherit;
//   }
.no-scroll {
    overflow: hidden;
}


// Landing component
.landing .header1 {
    width: 50%;
}
.landing .para1 {
    width: 45%;
}
.landing .section4 .para1 {
    width: 75%;
}
.landing-section1-form1 {
    width: 40%;
}
.landing-section1-btn2 {
    width: 100%;
}
.landing-section3-btn1 { 
    width: 30%;
}
.feature-card {
    background-color: $faded-green;
    padding: 40px 10px 30px 30px;
    text-align: left;
    border-radius: 10px;
    color: #103B00;
    line-height: 30px;
    height: 260px;
    transition: all .3s;
    cursor: default;
}
.feature-card:hover {
    transform: scale(1.02);
    transition: all .3s;
}
.feature-card h4{ 
    font-weight: 700;
}
.smartphone_floating {
    width: 38vw;
}
.smartphone_floating_with_rings {
    width: 50vw;
    margin-left: -60px;
}
.open-quote,
.close-quote {
    width: calc(7vw + 20px);
}
.testimonial {
    margin:auto;
    border: 1px solid #3EBF0F;
    width: 60vw;
    border-radius: 20px;
}
.testimonial-item {
    padding-left: 50px;
    padding-right: 50px;
}
.enterprise .testimonial-wrapper {
    margin-top: 100px;
}

// Footer component
.footer-line{
    width: 40%;
}
.media-icon{
    width: 20px;
    transition: all .3s;
}
.media-icon:hover { 
    transform: scale(1.2);
    cursor: pointer;
    transition: all .3s;
}
.Footer li {
    margin-top: 10px;
} 
.Footer .socials {
    width: 35%;
}
.Footer .socials img {
    margin-left: 20px;
}
@media screen and (max-width: 1000px) { 
    .Footer .socials {
        width: 100%;
    }
    .footer-line{
        width: 100%;
    }
}

// SelectPlan component
.free-plan,
.standard-plan {
    transition: all .6s;
}
.free-plan:hover,
.standard-plan:hover {
    transition: all .3s;
    z-index: 2;    
    transform: scale(1.05);
}
.standard-plan-modal:hover {
    transform: scale(1);
}
.free-plan ul,
.standard-plan ul {
    list-style-type: none;
}
.free-plan ul li span {
    position: relative;    
    top: -10px;
    left: -18px;
}
.free-plan ul li::before {
    content: url('./images/checkbox-green.png');
    width: 5px !important;
    position: relative;
    left: -20px;
    padding: 0px !important;
    margin: 0px !important;
}
.standard-plan ul li span {
    position: relative;    
    top: -10px;
    left: -18px;
}
.standard-plan ul li::before {
    content: url('./images/checkbox-blue.png');
    width: 5px !important;
    position: relative;
    left: -20px;
    padding: 0px !important;
    margin: 0px !important;
}
.standard-card {
    width: 400px;
    border: 0px !important;
}


// Steps component
.steps-container {
    border: solid 5px $faded-text;    
    //border-image: radial-gradient(45deg, $gen-color, $faded-text) 1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    z-index: 2;
}
.step-border {
    top: -5px;
    right: -5px;
}

// Modals 
#successregmodal {
    width: 30vw !important;
}
.custom-modal {
    top: 150px;
    width: fit-content !important;
    height: fit-content;
    box-shadow: 0px 0px 100vw 100vw rgba(0,0,0,.6);
    z-index: 1050;
}
.custom-modal-sec {
    height: fit-content;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1050;
    box-shadow: 0px 0px 100vw 100vw rgba(0,0,0,.6);
}
.custom-modal:hover {
    transform: scale(1) !important;
}
.control-btn {
    transition: all .3s;
    cursor: pointer;
}
.control-btn:hover {
    transform: scale(.92);
}    
.control-btn:active {
    transform: scale(1);
}
.plan-card {
    width: 30vw;
}


// ReserveNumber component
.reserve-num-form {
    width: 23vw;
}

// CheckoutForm component
.checkout-form {
    width: 50%;
}

.verify-otp-form-wrapper {
    margin: 150px 50px;
}
.verify-otp-form {
    width: 50%;
}

.custom-spinner {
    top: 50vh;
    z-index: 2000;
    // display: none !important;
}


// official docs
.official-docs h1 {
    text-align: center;
    margin-top: 50px;
    font-family: cursive;
  }
  
  .official-docs h5 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
  .official-docs div {
    word-spacing: 3px;
    margin: 30px 0px;
  }
  

  @media screen and (max-width: 1000px) { 
    // Landing component
    .feature-card {
        height: 350px;
    }

    // SelectPlan component 
    .plan-card {
        width: 40vw;
    }

}

@media screen and (max-width: 970px) { 
    // Landing component
    #successregmodal {
        width: 50vw !important;
    }    
    .landing-section1-form1 {
        width: 51%;
    }
    .reserve-num-form {
        width: 30vw;
    }    
    .verify-otp-form-wrapper {
        margin: 150px 0px;
    }
}

@media screen and (max-width: 768px) {
    // Landing component
    .testimonial {
        width: 80vw;
        border-radius: 20px;
    }
    .testimonial-header {
        position: relative;
        left: 10vw;
    }
    .landing-section1-form1 {
        width: 75%;
    }
    .landing-section3-btn1 { 
        width: 75% !important;
    }
    .landing h1 {
        width: 75% !important;
    }
    .landing .para1 {
        width: 75%;
    }
    .faqs {
        position: relative;
        top: -70px;
    }    
    .features-header {
        position: relative;
        left: 10vw;
    }
    .smartphone_floating_with_rings {
        width: 80vw;
        margin: auto;
    }

    // SelectPlan component
    .plan-card,
    .standard-card {
        width: 60vw;
    }
    .plan-card:hover {
        transform: scale(1.02);    
    }
    .plan-card:nth-child(2) {
        margin-top: 30px;
        padding-bottom: 40px;
    }
    .reserve-num-form {
        width: 40vw;
    }
    .reserve-num-form-wrapper button {
        width: 100%;
        margin-left: 10px;
    }
    // CheckoutForm component
    .checkout-form {
        width: 100%;
    }
    .verify-otp-form {
        width: 80%;
    }
}

@media screen and (max-width: 576px) {
    #successregmodal {
        width: 70vw !important;
    }    
    .did-select {
        font-size: 1rem !important;
    }    
    
    .landing-section1 {
        padding-bottom: 190px !important;
    }
    .landing h1 {
        width: 90% !important;
        padding-top: 20px !important;
    }
    .landing .section4 .para1 {
        width: 90%;
    }    
    
    .landing .para1 {
        width: 90%;
        line-height: 25px;
    }
    .landing .para2 {
        margin-bottom: 10px;
    }
    .landing-section1-form1 {
        width: 88%;
    }
    .landing-section1 {
        font-size: .9rem;
        padding-left: 0px;
    }
    .landing-section1-btn2 {
        margin-top: 15px;    
        width: 92.7%;
    }
    .landing-section3-btn1 { 
        margin-top: -80px !important;
    }
    // SelectPlan component
    .plan-card,
    .standard-card {
        width: 87vw;
    }
    .smartphone_floating {
        width: 83vw;
        margin-top: -150px !important;
    }
    .landing .section2 .header1 {
        margin-top: 15px !important;
    }
    .landing .section2 .para1 {
        margin-top: 0px !important;
    }
    .landing .section3 {
        padding-top: 15px !important;    
        margin-top: 100px !important;
    }
    .smartphone_floating_with_rings {
        width: 90vw;
    }
    
    .features-header {
        left: 10px;
    }
    .features-section1 .feature-card:first-child {
        margin-top: 0px;
    }
    .feature-card {
        margin-top: 24px ;
        width: 90vw !important;
        height: 260px !important;
    }
    .testimonial {
        width: 90vw;
        border-radius: 10px;
        margin-bottom: 0px !important;
    }
    .testimonial .open-quote {
        left: 10px !important;
    }
    .testimonial .close-quote {
        right: 10px !important;    
    }    
    .testimonial-item {
        padding-left: 30px;
        padding-right: 30px;
    }
    .testimonial-header {
        margin-top: -30px !important;    
        position: relative;
        top: -45px;
        left: 3vw;
    }
    .testimonial p {
        position: relative;
        top: -60px;
    }
    .reserve-num-form {
        width: 45vw;
    }
    .verify-otp-form {
        width: 100%;
    }
}

.reveal {
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: 1s all ease;
  }
.reveal.active {
    transform: translateY(0);
    opacity: 1;
  }
  .animate2 {
    position: relative;
    transform: translateX(100px);
    opacity: 0;
    transition: 1s all ease;
  }
  .animate2.active {
    transform: translateX(0);
    opacity: 1;
  }
  .animate3 {
    transform: translateX(-100px);
    opacity: 0;
    transition: 1s all ease;
  }
  .animate3.active {
    transform: translateX(0);
    opacity: 1;
  }
  

  // edits to v2
  .start {
    background-color: #EFF5FB !important;
  }
  .flag-container {
    position: relative;
  }
  
  .flag-name {
    position: absolute;
    top: 100%; /* Position it just below the image */
    left: 50%;
    transform: translateX(-50%); /* Center the name horizontally */
    background-color: white;
    color: green;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
  }
  
  .flag-container:hover .flag-name {
    opacity: 1; /* Display the name on hover */
  }
  .hero .heroText .ctaHold a.vid {
    text-transform: none;
  }

  .AboutUs .hero {
    background-color: white;
  }

  .look {
    background-color: #EFF5FB;  /* changes */
  }

  .navbar .dropdown .dropdown-toggle {
    color: rgb(115,115,115);
  }
  .navbar .dropdown .dropdown-toggle:hover {
    color:  black;
  }

  // enterprise
.faqs{
    padding-top: 130px;
  }


/* homepage */
.hero {
    gap: 20px;
}
.hero .heroDesk {
    top: -15px;
}
.hero .heroDesk img {
    border-radius: 20px;
  }
  
  @media only screen and (max-width: 481px) {
    .hero .heroText .ctaHold {
        width: 100% !important;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding: 0 4px 4px 0;
        position: relative; /* Add position: relative; for pseudo-element */
      }
    .faqs  {
        padding-top: 100px;
    }
    .faqs h1 {
        font-size: 24px !important;
    }

  }