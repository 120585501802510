*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Encode Sans', sans-serif;
};

/* .hero {
    flex: 1;
    width: 600px;
    margin: 0px 25px
} */
/* 
.container h1{
    color: #000000;
    font-size: 38px;
    text-align:start;
    margin-bottom: 25px;
} */

/* .container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 65px auto;
} */

.hero-content{
    flex: 1;
    width: 600px;
    margin: 0px 25px;
    /* padding: 0 25px; */
    /* border: 1px solid red; */
    /* gap: 15px; */
}
.about{
    background: none;
    padding: 10px 0 10px 15px;
    /* margin: 10px 0 10px 10px; */
}
.hero-content h1{
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 68.4px; 
    margin-bottom: 10px;
}

.hero-content p{
    margin-top: 15px;
    font-size: 18px;
    /* margin-bottom: 25px; */
    /* font-family: Encode Sans; */
    color: #666;
    font-style: normal;
    font-weight: 400;
    line-height: 33.9px;
}

.hero-image{
    flex: 1;
    /* width: 600px; */
    margin: auto;
}

.hero-image img{
    width: 600px;
}

/* .commit{
    background-color: none;
} */
/* img {
    width: 90%;
    height: 400px;
    border-radius: 30px;
    position: relative;
    left: 20px;
} */

.container-1 h1{
    color: #000000;
    font-size: 38px;
    text-align:center;
    margin-bottom: 25px
    
}

.container-image{
    width: 100%;
    position: relative;
    left: 35px;
}

.container-image img {
    width: 85vw;
    height: 458px;
    border-radius: 24px;
    margin: 0;
    background-color: green;
}

.container-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-1 h1{
    color: #000;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 68.4px;
}

.container-1 p{
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.9px;
}

.foot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 65px auto;
    /* background: none; */
}

.container-1{
    flex: 1;
    width: 600px;
    margin: 0px 25px
}

.feet h1{
    color: #000000;
    font-size: 38px;
    text-align:center;
    margin-bottom: 25px
}

.footer-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 65px auto;
}

@media screen and (max-width: 768px)
{
    .container h1{
        font-size: 45px;
        margin-top: 30px;
    }

    body {
        width: 100%;
    }

    .hero {
        margin: 0px;
    }
/* 
    .container{
        width: 100%;
        flex-direction: column;
        margin: 0px;
        padding: 0px 40px;
    } */

    .hero-content{
        width: 100%;
        margin: 35px 0px;
    }

    .hero-content h1{
        font-size: 35px;
    }

    .hero-content p{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .hero-image{
        width: 100%;
        height: auto;
        position: relative;
        right: 20px;
    }
    .foot h1{
        font-size: 45px;
        margin-top: 30px;
    }

    .foot{
        width: 100%;
        flex-direction: column;
        margin: 0px;
        padding: 0px 40px;
    }

    .container-1{
        width: 100%;
        margin: 35px 0px;
    }

    .container-1 h1{
        font-size: 35px;
    }

    .container-1 p{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .container-image{
        width: 100%;
        height: auto;
        position: relative;
        right: 20px;
    }

    .container-image img{
        width: 300px;
    }

}