@use 'variables';

@mixin flexer{
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexLine{
  @include flexer;
  display: inline-flex;
}

@mixin flexEnd{
  @include flexer;
  align-self: flex-end;
}

@mixin flexLineCol{
  @include flexLine;
  flex-direction: column;
}

@mixin flexCol{
  @include flexer;
  flex-direction: column;
}

@mixin flexBet{
  @include flexer;
  justify-content: space-between;
}

@mixin flexEven{
  @include flexer;
  justify-content: space-evenly;
}

@mixin flexColEven{
  @include flexCol;
  justify-content: space-evenly;
}

@mixin flexColBet{
  @include flexCol;
  justify-content: space-between;
}

@mixin flexStart{
  @include flexer;
  align-items: flex-start;
}

@mixin flexColStart{
  @include flexCol;
  align-items: flex-start;
}

@mixin query ($breakpoint){
  @if $breakpoint == mobile{
    @media only screen and (max-width: 481px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
}

// @mixin cta{
//   display: inline-block;
//   width: 351px;
//   height: 64px;
//   // display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 8px;
//   padding: 0 4px 4px 0;
//   position: relative; /* Add position: relative; for pseudo-element */

//   @include query(mobile){
//     width: 237.517px;
//     height: 48px;
//   }

//   &::before {
//     content: "";
//     background-image: url(../../images/cta.svg);
//     background-size: cover;
//     background-repeat: no-repeat;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1; /* Move the pseudo-element behind the content */
//     transform: scaleY(-1) scaleX(-1); /* Flip the background image */
//     border-radius: 8px;
//   }


//   a{
//     color: #fff;
//     text-transform: capitalize;
//     font-style: 16px;
//     font-weight: 500;
//     line-height: 16px;
//     background-color: map-get(variables.$colors , cta );
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     @include flexer;
//   }
// }

@mixin of-type($num){
  @if $num == 1{
    &:nth-of-type(1){
      @content;
    }
  }

  @else if $num == 2{
    &:nth-of-type(2){
      @content;
    }
  }

  @else if $num == 3{
    &:nth-of-type(3){
      @content;
    }
  }

  @else if $num == 4{
    &:nth-of-type(4){
      @content;
    }
  }

  @else if $num == 5{
    &:nth-of-type(5){
      @content;
    }
  }
}

@mixin para{
    font-size: 18px;
    font-weight: 400;
    line-height: 33.9px;
  
    @include query(mobile){
      text-align: center;
      font-size: 14px;
      line-height: 26.5px;
    }
}

@mixin head{
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
  
    @include query(mobile){
      text-align: center;
      font-size: 24px;
      line-height: 34px;
    }
}

@mixin cta{
  width: 355px;
  height: 68px;
  background: url(../../images/cta.svg);
  @include flexer;
  
  a{
      width: 355px;
      height: 68px;
      @include flexer;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
  }

  @include query(mobile){
      background: url(../../images/ctaMob.svg);
      width: 328px;
      height: 53px;
  }
}