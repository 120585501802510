.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly;
  background-color: #E6F7E0;
  background-image: url(../images/OBJECTS.svg);
  background-repeat: no-repeat;
  background-position: right bottom;
  /* border: 1px solid red; */
}
.hero .mob {
  display: none;
}
@media only screen and (max-width: 481px) {
  .hero {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 70px;
    padding: 20px 40px;
  }
}
.hero .heroText {
  display: flex;
  /* width: 625px; */
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: none;
  align-items: flex-start;
  gap: 24px;
}
.hero .heroText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-transform: capitalize;
  /* width: 60%; */
}
@media only screen and (max-width: 481px) {
  .hero .heroText h1 {
    text-align: center;
    text-transform: capitalize;
    font-size: 24px;
    line-height: 34px;
  }
}
.hero .heroText h1 span {
  text-transform: none;
}
@media only screen and (max-width: 481px) {
  .hero .heroText h1 {
    display: none;
  }
}
@media only screen and (max-width: 481px) {
  .hero .heroText .mob {
    display: block;
    font-size: 24px;
    font-weight: 700;
    line-height: 34px;
    text-align: center;
    text-transform: capitalize;
  }
}
.hero .heroText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
  text-align: justify;
  width: 67.8%;
}
@media only screen and (max-width: 481px) {
  .hero .heroText p {
    font-size: 14px;
    line-height: 26.5px;
    width: auto;
  }
}
.hero .heroText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}

.hero .heroText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.hero .heroText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero .heroDesk {
  position: relative;
}

.hero .heroDesk span {
  position: absolute;
  padding: 25px 19px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  gap: 10px;
  background-color: #C5EDB7;
  top: 5px;
  left: 248px;
  font-size: 12px;
  font-weight: 600;
  line-height: 160%;
}
@media only screen and (max-width: 481px) {
  .hero .heroDesk {
    display: none;
  }
}
@media only screen and (max-width: 481px) {
  .hero .heroMob {
    display: block;
    position: relative;
  }
  .hero .heroMob #play {
    position: absolute;
    right: 50%;
    top: 50%;
  }
}

.you {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
@media only screen and (max-width: 481px) {
  .you {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.you .hand {
  width: 577px;
  height: 664px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  background-color: #E6F7E0;
}
.you .hand img {
  width: 555.323px;
  height: 491.1px;
}
@media only screen and (max-width: 481px) {
  .you .hand {
    display: none;
  }
}
.you .youText {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* gap: 20px; */
}
@media only screen and (max-width: 481px) {
  .you .youText .ctaHold{
    align-self: center;
  }
}
.you .youText h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 50.492px;
  color: #115BC7;
  text-transform: capitalize;
}
@media only screen and (max-width: 481px) {
  .you .youText h4 {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.you .youText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .you .youText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.you .youText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}

@media only screen and (max-width: 481px) {
  .you .youText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.you .youText ul {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.you .youText ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.you .youText ul li span {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFF5FB;
  border-radius: 50%;
}
.you .youText ul li:last-child span {
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
}
@media only screen and (max-width: 481px) {
  .you .youText ul li span {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
}
.you .youText ul li p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5; /* Adjust the value as needed */
}
@media only screen and (max-width: 481px) {
  .you .youText ul li p {
    font-size: 14px;
  }
}
/* .you .youText ul li #gen span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFF5FB;
  border-radius: 50%;
} */
/* @media only screen and (max-width: 481px) {
  .you .youText ul li #gen span {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }
} */
/* .you .youText ul li #gen p {
  line-height: 20px;
} */
/* @media only screen and (max-width: 481px) {
  .you .youText ul li #gen p {
    line-height: 24px;
    text-align: justify;
  }
} */
.you .youText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .you .youText .ctaHold {
    width: 237.517px;
    height: 48px;

  }
}
.you .youText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.you .youText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .you .youText .ctaHold a {
    font-size: 14px;
    line-height: 18.562px;
  }
}
@media only screen and (max-width: 481px) {
  .you .youText .mob {
    width: 328px;
    height: 373px;
    border-radius: 13.5px;
    background-color: #F2FBEF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
  }
  .you .youText .mob img {
    width: 311.904px;
    height: 275.832px;
  }
}

.business {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media only screen and (max-width: 481px) {
  .business {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.business .busiText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 566px;
}
@media only screen and (max-width: 481px) {
  .business .busiText {
    width: 328px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.business .busiText h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 50.492px;
  color: #115BC7;
  text-transform: capitalize;
}
@media only screen and (max-width: 481px) {
  .business .busiText h4 {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.business .busiText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .business .busiText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.business .busiText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .business .busiText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.business .busiText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .business .busiText .ctaHold {
    width: 237.517px;
    height: 48px;
  }
}
.business .busiText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.business .busiText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .business .busiText .ctaHold a {
    font-size: 14px;
    line-height: 18.562px;
  }
}
.business .busiImg {
  width: 577px;
  height: 514px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  background-color: #E6F7E0;
}
.business .busiImg img {
  height: 514px;
}
@media only screen and (max-width: 481px) {
  .business .busiImg {
    width: 328px;
    height: 373px;
    border-radius: 13.5px;
    background-color: #F2FBEF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
  }
  .business .busiImg img {
    width: 311.904px;
    height: 275.832px;
  }
  .business .busiImg img {
    width: 265.273px;
    height: 335px;
  }
}

.ent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
@media only screen and (max-width: 481px) {
  .ent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-direction: column-reverse;
  }
}
.ent .entImg {
  width: 577px;
  height: 600px;
  background: url(../images/green.png) no-repeat center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
}
@media only screen and (max-width: 481px) {
  .ent .entImg {
    width: 328px;
    height: 373px;
  }
  .ent .entImg img {
    width: 331.083px;
    height: 309.891px;
  }
}
.ent .entText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 481px) {
  .ent .entText {
    width: 328px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.ent .entText h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 50.492px;
  color: #115BC7;
  text-transform: capitalize;
}
@media only screen and (max-width: 481px) {
  .ent .entText h4 {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.ent .entText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .ent .entText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.ent .entText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .ent .entText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  .ent .entText p {
    line-height: 33.9px;
  }
}
.ent .entText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .ent .entText .ctaHold {
    width: 237.517px;
    height: 48px;
  }
}
.ent .entText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.ent .entText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .ent .entText .ctaHold a {
    font-size: 14px;
    line-height: 18.562px;
  }
}

.impact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FAFAFA;
}
.impact h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .impact h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  .impact h1 {
    text-align: center;
  }
}
.impact .pactText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  position: relative;
}
@media only screen and (max-width: 481px) {
  .impact .pactText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
}
.impact .pactText .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.impact .pactText .hold span {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #E4EFFF url(../images/100k.png) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .impact .pactText .hold span {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-size: 16.739px 20.587px;
  }
}
.impact .pactText .hold div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
.impact .pactText .hold div h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  font-size: 36px;
}
@media only screen and (max-width: 481px) {
  .impact .pactText .hold div h2 {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 481px) {
  .impact .pactText .hold div h2 {
    font-size: 22px;
    text-align: center;
  }
}
.impact .pactText .hold div p {
  font-size: 18px;
  font-weight: 400;
  line-height: 26.9px;
}
@media only screen and (max-width: 481px) {
  .impact .pactText .hold div p {
    font-size: 14px;
    line-height: 20px;
  }
}
.impact .pactText .hold2 span {
  background: #E4EFFF url(../images/shop.png) no-repeat center;
}

.impact .pactText .hold2{
  position: relative;
  left: -30px;
}

@media only screen and (max-width: 481px){
  .impact .pactText .hold2{
    position: relative;
    left: 0;
  }
}

@media only screen and (max-width: 481px) {
  .impact .pactText .hold2 span {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-size: 16.739px 20.587px;
  }
}
.impact .pactText .hold3 span {
  background: #E4EFFF url(../images/agent.png) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .impact .pactText .hold3 span {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-size: 16.739px 20.587px;
  }
}

.smart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FAFAFA;
  gap: 50px;
  padding-top: 120px;
}
.smart img {
  width: 35vw;
}
.smart .smartText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

@media only screen and (max-width: 481px) {
  .smart .smartText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    order: 1;
  }
  .smart {
    padding-top: 50px;
  }
  .smart img {
    width: 80vw;
  }
}
.smart .smartText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .smart .smartText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  .smart .smartText h1 {
    width: 322px;
    height: 79px;
  }
}
@media only screen and (max-width: 481px) {
  .smart .smartText .img {
    display: block;
    width: 100%;
    height: 344.333px;
    background: url(../images/smartphone\ floating\ 1.png) no-repeat center;
    background-size: 100%;
  }
}
.smart .smartText .smartHold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
@media only screen and (max-width: 481px) {
  .smart .smartText .smartHold {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5px;
  }
}
.smart .smartText .smartHold .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
  width: 208px;
  height: 52px;
}
@media only screen and (max-width: 481px) {
  .smart .smartText .smartHold .ctaHold {
    width: 237.517px;
    height: 48px;
  }
}
.smart .smartText .smartHold .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.smart .smartText .smartHold .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .smart .smartText .smartHold .ctaHold {
    width: 158px;
    height: 48px;
  }
}
/* .smart .img {
  width: 802.538px;
  height: 542.811px;
  background: url(../images/smartphone\ floating\ 1.png) no-repeat center;
} */
@media only screen and (max-width: 481px) {
  .smart .img {
    display: none;
  }
}

.val {
  display: flex;
  align-items: center;
  justify-content: center;
}
.val div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 1233px;
  height: 437px;
  border-radius: 24px;
  gap: 20px;
  background: url(../images/val.png) center no-repeat;
}
@media only screen and (max-width: 481px) {
  .val div {
    width: 328px;
    height: 256px;
    border-radius: 6.384px;
  }
}
.val div h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  width: 620px;
  height: 165px;
}
@media only screen and (max-width: 481px) {
  .val div h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  .val div h1 {
    text-align: center;
    width: 284px;
    height: 111px;
  }
}
.val div .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .val div .ctaHold {
    width: 237.517px;
    height: 48px;
  }
}
.val div .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.val div .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .val div .ctaHold {
    width: 158px;
    height: 48px;
  }
}

.look {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(230, 247, 224, 0.52); */
}
@media only screen and (max-width: 481px) {
  .look {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 40px;
  }
}
.look .lookText {
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: none;
  align-items: flex-start;
  gap: 24px;
}
@media only screen and (max-width: 481px) {
  .look .lookText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.look .lookText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .look .lookText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.look .lookText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .look .lookText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  .look .lookText p {
    text-align: center;
  }
}
.look .lookText .lookHold {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0 0;
  gap: 30px;
}
@media only screen and (max-width: 481px) {
  .look .lookText .lookHold {
    display: none;
  }
}
.look .lookImg {
  width: 908.666px;
  height: 605.777px;
  background: url(../images/smartphone\ floating\ 1.png) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .look .lookImg {
    width: 100%;
    height: 334.372px;
    background-size: 100%;
  }
}

.call {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
@media only screen and (max-width: 481px) {
  .call {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.call .callImg {
  width: 577px;
  height: 664px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  background-color: #F2FBEF;
  height: 568px;
}
.call .callImg img {
  width: 555.323px;
  height: 491.1px;
}
@media only screen and (max-width: 481px) {
  .call .callImg {
    width: 328px;
    height: 373px;
    border-radius: 13.5px;
    background-color: #F2FBEF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
  }
  .call .callImg img {
    width: 311.904px;
    height: 275.832px;
  }
}
.call .callText {
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: none;
  align-items: flex-start;
  gap: 24px;
}
@media only screen and (max-width: 481px) {
  .call .callText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.call .callText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .call .callText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.call .callText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .call .callText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  .call .callText p {
    text-align: center;
  }
}
.call .callText .callHold {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 0 0;
  gap: 30px;
}
@media only screen and (max-width: 481px) {
  .call .callText .callHold {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
  }
  .call .callText .callHold img {
    width: 199.177px;
    height: 57.732px;
  }
}

.what {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.what h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .what h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.what .whatHold {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.what .whatHold .top {
  margin-bottom: 20px;
}
.what .whatHold .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.what .whatHold .holder .holds {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 608px;
  height: 350px;
  border-radius: 24px;
  background-color: #F2FBEF;
  padding: 0 25px;
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds {
    width: 260px;
    height: 328px;
    border-radius: 10.226px;
  }
}
.what .whatHold .holder .holds .img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background: url(../images/gcloud.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds .img {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-size: 100%;
  }
}
.what .whatHold .holder .holds .img2 {
  background: url(../images/timer.svg);
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds .img2 {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-size: 100%;
  }
}
.what .whatHold .holder .holds .img3 {
  background: url(../images/incom.svg);
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds .img3 {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-size: 100%;
  }
}
.what .whatHold .holder .holds .img4 {
  background: url(../images/unlimi.svg);
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds .img4 {
    width: 51px;
    height: 51px;
    border-radius: 50%;
    background-size: 100%;
  }
}
.what .whatHold .holder .holds h4 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  font-size: 24px;
  line-height: 40px;
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds h4 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds h4 {
    font-size: 18px;
    line-height: 25.1px;
  }
}
.what .whatHold .holder .holds p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .what .whatHold .holder .holds p {
    font-size: 14px;
    line-height: 26.5px;
  }
}

.start {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(230, 247, 224, 0.52); */
  gap: 40px;
}
@media only screen and (max-width: 481px) {
  .start {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.start .startText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}
@media only screen and (max-width: 481px) {
  .start .startText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
}
.start .startText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .start .startText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 481px) {
  .start .startText h1 {
    line-height: 68.4px;
  }
}
.start .startText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .start .startText p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  .start .startText p {
    text-align: center;
    font-size: 16px;
    line-height: 33.9px;
  }
}
.start .startText ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
}
@media only screen and (max-width: 481px) {
  .start .startText ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
}
.start .startText ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
  font-weight: 500;
}
@media only screen and (max-width: 481px) {
  .start .startText ul li {
    font-size: 14px;
    line-height: 26.5px;
  }
}
@media only screen and (max-width: 481px) {
  .start .startText ul li {
    font-weight: 500;
    font-size: 16px;
    line-height: 33.9px;
  }
}
.start .startText ul li span {
  width: 24px;
  height: 24px;
  background: url(../images/dash.svg);
}
.start .startText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .start .startText .ctaHold {
    width: 237.517px;
    height: 48px;
  }
}
.start .startText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.start .startText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 481px) {
  .start .startText .ctaHold {
    display: none;
  }
}
.start .startImg {
  width: 577px;
  height: 664px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  background-color: #F2FBEF;
  height: 568px;
  background: #80DFFF;
}
.start .startImg img {
  width: 555.323px;
  height: 491.1px;
}
@media only screen and (max-width: 481px) {
  .start .startImg {
    width: 328px;
    height: 373px;
    border-radius: 13.5px;
    background-color: #F2FBEF;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
    width: 328.652px;
    height: 323.525px;
    border-radius: 13.67px;
    background: #80DFFF;
  }
  .start .startImg img {
    width: 311.904px;
    height: 275.832px;
  }
}
.start .startImg img {
  width: 600px;
  height: 600px;
}
@media only screen and (max-width: 481px) {
  .start .startImg img {
    width: 453px;
    height: 341.752px;
  }
}

.cloud {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2FBEF;
}
.cloud .cloudText {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  gap: 40px;
  padding: 0 25px;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 327px;
  }
}
.cloud .cloudText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.cloud .cloudText p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.9px;
}
.cloud .cloudText .linker {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  gap: 35px;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText .linker {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.cloud .cloudText .linker .cta {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 351px;
  height: 64px;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  background-color: #3EBF0F;
  color: #fff;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText .linker .cta {
    width: 327px;
  }
}
.cloud .cloudText .linker .noCta {
  color: #3EBF0F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.cloud .cloudText .vid {
  display: none;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText .vid {
    display: block;
    width: 100%;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/* .cloud .cloudText .trust {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText .trust {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 327px;
  }
}
.cloud .cloudText .trust p {
  color: #8F8F8F;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
@media only screen and (max-width: 481px) {
  .cloud .cloudText .trust p {
    display: none;
  }
}
.cloud .cloudText .trust ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
} */
@media only screen and (max-width: 481px) {
  .cloud .cloudText .trust ul {
    width: 327px;
  }
  .cloud .cloudText .trust ul li img {
    width: 81.767px;
  }
}
.cloud .vid {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cloud .vid #vid {
  width: 100%;
}
@media only screen and (max-width: 481px) {
  .cloud .vid {
    display: none;
  }
}
@media only screen and (max-width: 481px) {
  .cloud {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
}

.simp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.simp .simpTop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
.simp .simpTop h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  margin: 8px 0;
}
@media only screen and (max-width: 481px) {
  .simp .simpTop h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
  }
}
.simp .simpTop p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .simp .simpTop p {
    font-size: 14px;
    line-height: 26.5px;
    text-align: center;
  }
}
.simp .opt {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.simp .opt #opt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 25px;
  }
}
.simp .opt #opt .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 50%;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.simp .opt #opt .hold .img {
  width: 152px;
  height: 160px;
  background: #EFF5FB url(../images/set.svg) no-repeat center;
  border-radius: 16px;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .img {
    width: 70.3px;
    height: 74px;
    border-radius: 4px;
    background: #EFF5FB url(../images/mobSet.svg) no-repeat center;
  }
}
.simp .opt #opt .hold .img2 {
  background: #EFF5FB url(../images/scale.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .img2 {
    background: #EFF5FB url(../images/mobScale.svg) no-repeat center;
  }
}
.simp .opt #opt .hold .img3 {
  background: #EFF5FB url(../images/control.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .img3 {
    background: #EFF5FB url(../images/mobCont.svg) no-repeat center;
  }
}
.simp .opt #opt .hold .img4 {
  background: #EFF5FB url(../images/manager.svg) no-repeat center;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .img4 {
    background: #EFF5FB url(../images/mobMan.svg) no-repeat center;
  }
}
.simp .opt #opt .hold .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 426px;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 328px;
  }
}
.simp .opt #opt .hold .text h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .text h4 {
    font-size: 18px;
  }
}
.simp .opt #opt .hold .text p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .simp .opt #opt .hold .text p {
    text-align: center;
    font-size: 16px;
  }
}

.cust {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.cust .custTop {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.cust .custTop h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  line-height: 68.4px;
  text-align: center;
  width: 728px;
  height: 164px;
}
@media only screen and (max-width: 481px) {
  .cust .custTop{
    width: 328px;
    height: 144px;
  }
  .cust .custTop h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    width: 328px;
    margin: 0;
  }
}
.cust .custTop p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
  margin: 0;
}
@media only screen and (max-width: 481px) {
  .cust .custTop p {
    font-size: 14px;
    line-height: 26.5px;
  }
}
.cust .custBot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
}
.cust .custBot .hold {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
.cust .custBot .hold .holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 328px;
}
.cust .custBot .hold .holder .img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background: #F2FBEF url(../images/realD.svg) no-repeat center;
}
.cust .custBot .hold .holder .img2 {
  background: #F2FBEF url(../images/live.svg) no-repeat center;
}
.cust .custBot .hold .holder .img3 {
  background: #F2FBEF url(../images/queD.svg) no-repeat center;
}
.cust .custBot .hold .holder .img4 {
  background: #F2FBEF url(../images/callWhisp.svg) no-repeat center;
}
.cust .custBot .hold .holder .img5 {
  background: #F2FBEF url(../images/crmInt.svg) no-repeat center;
}
.cust .custBot .hold .holder .img6 {
  background: #F2FBEF url(../images/callMon.svg) no-repeat center;
}
.cust .custBot .hold .holder h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 73.4px;
}
.cust .custBot .hold .holder p {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px){
  .cust .custBot .hold{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cust .custBot .hold .holder{
    width: 85vw;
    height: 328px;
    background: #F2FBEF;
    border-radius: 10.226px;
    padding: 20px;
  }
  .cust .custBot .hold .holder .img{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #3EBF0F url(../images/twine.svg) no-repeat center;
    margin: 0;
  }
  .cust .custBot .hold .holder .img2 {
    background: #3EBF0F url(../images/liveMob.svg) no-repeat center;
  }
  .cust .custBot .hold .holder .img3 {
    background: #3EBF0F url(../images/callMob.svg) no-repeat center;
  }
  .cust .custBot .hold .holder .img4 {
    background: #3EBF0F url(../images/whisMob.svg) no-repeat center;
  }
  .cust .custBot .hold .holder .img5 {
    background: #3EBF0F url(../images/crmMob.svg) no-repeat center;
  }
  .cust .custBot .hold .holder .img6 {
    background: #3EBF0F url(../images/monMob.svg) no-repeat center;
  }

  .cust .custBot .hold .holder p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.8px;
    margin: 0;
  }

  .cust .custBot .hold .holder h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 73.4px;
    margin: 0;
  }
}
.cent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.cent .centText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
@media only screen and (max-width: 481px){
  .cent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  .cent .centText{
    align-items: center;
  }

  .cent .centText p{
    text-align: center;
  }
}
.cent .centText h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}
@media only screen and (max-width: 481px) {
  .cent .centText h1 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    margin: 0;
  }
}
.cent .centText p {
  font-size: 18px;
  font-weight: 400;
  line-height: 33.9px;
}
@media only screen and (max-width: 481px) {
  .cent .centText p {
    font-size: 14px;
    line-height: 26.5px;
    margin: 0;
  }
}
.cent .centText .ctaHold {
  display: inline-block;
  width: 351px;
  height: 64px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 0 4px 4px 0;
  position: relative; /* Add position: relative; for pseudo-element */
}
@media only screen and (max-width: 481px) {
  .cent .centText .ctaHold {
    width: 237.517px;
    height: 48px;
    margin: 0;
  }
}
.cent .centText .ctaHold::before {
  content: "";
  background-image: url(../images/cta.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Move the pseudo-element behind the content */
  transform: scaleY(-1) scaleX(-1); /* Flip the background image */
  border-radius: 8px;
}
.cent .centText .ctaHold a {
  color: #fff;
  text-transform: capitalize;
  font-style: 16px;
  font-weight: 500;
  line-height: 16px;
  background-color: #3EBF0F;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cent .centImg {
  width: 480px;
  height: 437px;
  background: url(../images/callWoman.png) no-repeat center;
  border-radius: 24px;
}
@media only screen and (max-width: 481px) {
  .cent .centImg {
    width: 326.389px;
    height: 297.15px;
    margin: 0;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Encode Sans", sans-serif;
  font-style: normal;
}
body section {
  padding: 50px;
}
@media only screen and (max-width: 481px) {
  body section {
    padding: 30px;
  }
}
body .mob {
  display: none;
}
@media only screen and (max-width: 481px) {
  body .mob {
    display: block;
  }
}/*# sourceMappingURL=style.css.map */